import { ref } from '@vue/composition-api';
import toast from '@/utils/toast';
import store from '@/store';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import invoiceStoreModule from '@/views/finance/invoice/invoiceStoreModule';

export default function useSearchInvoice(props, emit, refFormObserver) {
  const toastification = toast();

  const STORE_MODULE_NAME = 'invoice';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, invoiceStoreModule);
  }

  const refForm = ref(refFormObserver);
  const blankItem = {
    phone: '',
    studentCode: '',
  };
  const itemLocal = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(blankItem));
  };
  const { t } = useI18nUtils();
  const onSubmit = () => {
    store.dispatch('invoice/searchInvoice', itemLocal.value).then(() => {
      window.location.href = `/my-invoices?phone=${itemLocal.value.phone}&studentCode=${itemLocal.value.studentCode}`;
    }).catch(error => {
      toastification.showToastError(error);
    });
  };

  return {
    refForm,
    itemLocal,
    resetItemLocal,
    onSubmit,
    t,
  };
}
