<template>
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">
  
        <!-- Login v1 -->
        <b-card class="mb-0">
          <brand />
  
          <b-card-title class="mb-1">
            {{ t('Cổng tra cứu học phí') }}! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            {{ t('Vui lòng cung cấp số điện thoại và mã học sinh để tra cứu hóa đơn') }}!
          </b-card-text>
  
          <!-- form -->
          <validation-observer
            ref="form"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="onSubmit"
            >
  
              <!-- phone -->
              <b-form-group
                label-for="phone"
              >
                <template
                  v-slot:label
                >
                  {{ t('Số điện thoại phụ huynh') }}
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Số điện thoại phụ huynh"
                  rules="required"
                >
                  <b-form-input
                    id="phone"
                    v-model="itemLocal.phone"
                    name="phone"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0868987355"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
  
              <!-- phone -->
              <b-form-group
                label-for="studentCode"
              >
                <template
                  v-slot:label
                >
                  {{ t('Mã học sinh') }}
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Mã học sinh"
                  rules="required"
                >
                  <b-form-input
                    id="studentCode"
                    v-model="itemLocal.studentCode"
                    name="studentCode"
                    :state="errors.length > 0 ? false:null"
                    placeholder="HD000001"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
  
  
              <!-- submit button -->
              <b-button
                variant="primary"
                type="submit"
                block
                :disabled="invalid"
              >
                {{ t('Tra cứu') }}
              </b-button>
            </b-form>
          </validation-observer>
  
        </b-card>
        <!-- /Login v1 -->
      </div>
    </div>
  </template>
  
  <script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import {
    BButton, BForm, BFormInput, BFormGroup, BCard, BCardTitle, BCardText,
  } from 'bootstrap-vue';
  import { required } from '@validations';
  import Brand from '@/layouts/components/Brand.vue';
  import useSearchInvoice from './useSearchInvoice';
  
  export default {
    components: {
      // BSV
      BButton,
      BForm,
      BFormInput,
      BFormGroup,
      BCard,
      BCardTitle,
      Brand,
      BCardText,
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        // validation rules
        required,
      };
    },
  
    setup() {
      const { itemLocal, onSubmit, t } = useSearchInvoice();
  
      return {
        itemLocal,
        onSubmit,
        t,
      };
    },
  
  };
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  </style>
  